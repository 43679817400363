import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { getMonthToDate } from '@/utils/time';
import { useTimeframe } from '@/hooks/useTimeframe';
import { DateRange } from '@/types/dates';
import { type Reports } from './utils';

type ReportsValue = {
  reports: Reports;
  timeframe: DateRange;
  onUpdateTimeframe: (update: DateRange) => void;
  onAddReports: (reports: Partial<Reports>) => void;
};

const ReportsContext = createContext<ReportsValue>({
  reports: {},
  timeframe: getMonthToDate(),
  onUpdateTimeframe: () => null,
  onAddReports: () => null,
});

export default function ReportsProvider({ children }: PropsWithChildren) {
  const [timeframe, setTimeframe] = useTimeframe(getMonthToDate());
  const [reports, setReports] = useState<Reports>({});

  const handleAddReports = (newReports: Partial<Reports> = {}) => {
    // NOTE: object key should be report name
    setReports((reports) => ({ ...reports, ...newReports }));
  };

  const contextValue = {
    reports,
    timeframe,
    onUpdateTimeframe: (update: DateRange) => setTimeframe(update),
    onAddReports: handleAddReports,
  };

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
}

export function useReports() {
  const context = useContext(ReportsContext);
  if (!context) {
    throw Error('useReports must be used within the ReportsProvider');
  }
  return context;
}
