import { useEffect, useRef, useState } from 'react';
import { endOfDay, isBefore, parseISO, startOfDay } from 'date-fns';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import DefaultOptionsList from './DefaultOptionsList';
import DateRangeCalendar from '@/components/DateRangeCalendar';
import DateRangeTextInput from './DateRangeTextInput';
import Button from '@/components/Button';
import { useToggle } from '@/hooks/useToggle';
import { type DateRange } from '@/types/dates';
import {
  ACTION,
  classes,
  getInitialEnd,
  getInitialStart,
  styles,
} from './utils';

const { PICK_START, PICK_END } = ACTION;

export type DateRangeInputProps<N extends string> = {
  className?: string;
  disablePast?: boolean;
  name: N;
  label: string;
  variant: 'text' | 'outlined';
  value: DateRange;
  weekStartsOn?: 0 | 1;
  dateFormat?: string;
  onChange?: (update: Record<N, DateRange>) => void;
  onApply?: (update: Record<N, DateRange>) => void;
};

export default function DateRangeInput<N extends string>({
  className = '',
  disablePast = false,
  variant = 'outlined',
  name,
  label,
  value,
  weekStartsOn,
  dateFormat,
  onChange,
  onApply,
}: DateRangeInputProps<N>) {
  const inputRef = useRef(null);
  const [isOpen, toggleOpen] = useToggle(false);
  const [currentAction, setCurrentAction] = useState(PICK_START);
  const [start, setStart] = useState(getInitialStart(value));
  const [end, setEnd] = useState(getInitialEnd(value));
  const [activeDate, setActiveDate] = useState(start);

  useEffect(() => {
    if (onChange) onChange({ [name]: [start, end] } as Record<N, DateRange>);
  }, [start, end]);

  useEffect(() => {
    // Close the popover if the user presses tab, enter, or escape
    function checkKeyPress(e: KeyboardEvent) {
      handleKeyPress(e);
    }
    if (isOpen) {
      window.addEventListener('keyup', checkKeyPress);
    } else {
      window.removeEventListener('keyup', checkKeyPress);
    }
    return () => {
      window.removeEventListener('keyup', checkKeyPress);
    };
  }, [isOpen]);

  const handleKeyPress = (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (['Tab', 'Enter', 'Escape'].includes(e.key)) {
      toggleOpen(false);
    }
  };

  const handleClose = () => {
    if (onApply) {
      setStart(getInitialStart(value));
      setEnd(getInitialEnd(value));
    }
    toggleOpen(false);
  };

  const handleApply = () => {
    if (onApply) {
      onApply({ [name]: [start, end] } as Record<N, DateRange>);
    }
    toggleOpen(false);
  };

  const handleSelectDate = (date: Date) => {
    const startDate = startOfDay(date).toISOString();
    const endDate = endOfDay(date).toISOString();
    if (currentAction === PICK_START) {
      setStart(startDate);
      setEnd(endDate);
      setActiveDate(startDate);
      setCurrentAction(PICK_END);
    } else {
      if (isBefore(date, parseISO(start))) {
        // Values are reversed
        setStart(startDate);
        setEnd(endOfDay(parseISO(start)).toISOString());
        setActiveDate(startDate);
        setCurrentAction(PICK_START);
      } else {
        setEnd(endDate);
        setActiveDate(endDate);
        setCurrentAction(PICK_START);
      }
    }
  };

  const handleSelectDefaultTimeframe = (timeframe: DateRange) => {
    setStart(timeframe[0]);
    setEnd(timeframe[1]);
    setActiveDate(timeframe[1]);
    setCurrentAction(PICK_START);
  };

  return (
    <FormControl
      className={className}
      sx={styles.formControl(isOpen)}
      size="small"
      variant="outlined">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <DateRangeTextInput
        ref={inputRef}
        open={isOpen}
        variant={variant}
        name={name}
        label={label}
        start={start}
        end={end}
        dateFormat={dateFormat}
        onClick={() => toggleOpen()}
      />
      <Popover
        open={isOpen}
        sx={styles.popover}
        anchorEl={inputRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={handleClose}>
        <Paper className={classes.paper}>
          <DefaultOptionsList
            extended={!!onApply}
            weekStartsOn={weekStartsOn}
            onSelect={handleSelectDefaultTimeframe}
          />
          <Divider orientation="vertical" flexItem />
          <div>
            <DateRangeCalendar
              disablePast={disablePast}
              weekStartsOn={weekStartsOn}
              activeDate={activeDate}
              start={start}
              end={end}
              onChange={handleSelectDate}
            />
            {onApply && (
              <div className={classes.buttonWrapper}>
                <Button className={classes.button} onClick={handleApply}>
                  Apply
                </Button>
              </div>
            )}
          </div>
        </Paper>
      </Popover>
    </FormControl>
  );
}
