import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DateCalendar,
  type DateCalendarProps,
} from '@mui/x-date-pickers/DateCalendar';
import {
  PickersDay,
  type PickersDayProps,
} from '@mui/x-date-pickers/PickersDay';
import { parseISO, setDefaultOptions } from 'date-fns';
import { getDayClassName, getDayStyling, styles } from './utils';

export type DateRangeCalendarProps = {
  disablePast?: boolean;
  weekStartsOn?: 0 | 1;
  activeDate: string;
  start: string;
  end: string;
  onChange: DateCalendarProps<any>['onChange'];
};

export default function DateRangeCalendar({
  disablePast,
  weekStartsOn = 0,
  activeDate,
  start,
  end,
  onChange,
}: DateRangeCalendarProps) {
  setDefaultOptions({ weekStartsOn });

  const renderDay = (props: PickersDayProps<Date>) => {
    return (
      <PickersDay
        {...props}
        sx={getDayStyling(props.day, start, end)}
        className={getDayClassName(props.className, props.day, start, end)}
      />
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateCalendar
        showDaysOutsideCurrentMonth
        disablePast={disablePast}
        value={parseISO(activeDate)}
        sx={styles.calendar}
        slots={{
          day: (props) => renderDay(props),
        }}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}
