import { isValid } from 'date-fns';
import { DateRange } from '@/types/dates';

export function getInitialValue(
  searchParams: URLSearchParams,
  defaultValue: DateRange
) {
  const startDate = searchParams.get('startDate');
  const endDate = searchParams.get('endDate');
  return hasValidValue(startDate) && hasValidValue(endDate)
    ? ([startDate, endDate] as DateRange)
    : defaultValue;
}

function hasValidValue(date?: string | null) {
  return date && isValid(new Date(date));
}
