import {
  LAST_MONTH,
  LAST_WEEK,
  LAST_YEAR,
  MONTH_TO_DATE,
  PAST_30_DAYS,
  PAST_7_DAYS,
  PAST_90_DAYS,
  THIS_WEEK,
  type Timeframe,
  TODAY,
  YEAR_TO_DATE,
  YESTERDAY,
} from '@/constants/timeframes';
import {
  getLastMonth,
  getLastWeek,
  getLastYear,
  getMonthToDate,
  getPastNinetyDays,
  getPastSevenDays,
  getPastThirtyDays,
  getThisWeek,
  getToday,
  getYearToDate,
  getYesterday,
} from '@/utils/time';
import { DateRange } from '@/types/dates';

export const classes = {
  list: 'px-1 py-4 max-h-[350px] overflow-y-auto bg-grey-1',
  unextended: 'max-h-[350px]',
  extended: 'max-h-[384px]', // To accommodate the Apply button
};

export function getDefinedTimeframe(
  timeframe: Timeframe,
  weekStartsOn: Day = 0
): DateRange {
  switch (timeframe) {
    case TODAY:
      return getToday();
    case YESTERDAY:
      return getYesterday();
    case THIS_WEEK:
      return getThisWeek(weekStartsOn);
    case PAST_7_DAYS:
      return getPastSevenDays();
    case LAST_WEEK:
      return getLastWeek(weekStartsOn);
    case MONTH_TO_DATE:
      return getMonthToDate();
    case LAST_MONTH:
      return getLastMonth();
    case PAST_30_DAYS:
      return getPastThirtyDays();
    case PAST_90_DAYS:
      return getPastNinetyDays();
    case YEAR_TO_DATE:
      return getYearToDate();
    case LAST_YEAR:
      return getLastYear();
    default:
      throw Error(`Unrecognized timeframe: "${timeframe}"`);
  }
}
