import { forwardRef } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@/icons/EventIcon';
import { classes, renderTimeframe, styles } from './utils';

export type DateRangeTextInputProps = {
  open?: boolean;
  variant: 'text' | 'outlined';
  name: string;
  label: string;
  start: string;
  end: string;
  dateFormat?: string;
  onClick: () => void;
};

const DateRangeTextInput = forwardRef<any, DateRangeTextInputProps>(
  ({ open, variant, name, label, start, end, dateFormat, onClick }, ref) => {
    const inputProps = {
      id: name,
      label,
      value: renderTimeframe(start, end, dateFormat),
      onClick,
      endAdornment: (
        <InputAdornment position="end">
          <EventIcon className={classes.icon} />
        </InputAdornment>
      ),
    };
    return variant === 'outlined' ? (
      <OutlinedInput ref={ref} {...inputProps} />
    ) : (
      <Input
        ref={ref}
        disableUnderline
        sx={styles.textInput(open)}
        {...inputProps}
      />
    );
  }
);

export default DateRangeTextInput;
