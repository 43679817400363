import { renderTextDate } from '@/utils/time';
import { theme } from '@/utils/theme';

export const classes = {
  icon: 'cursor-pointer text-grey-7',
};

export const styles = {
  textInput: (isOpen?: boolean) => ({
    '&': {
      caretColor: 'transparent',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      paddingBottom: isOpen ? '3px' : '4px', // Offset the border width
      transition: 'border-color 0.2s',
      borderRadius: '0px !important',
      borderBottom: isOpen
        ? `2px solid ${theme.palette['purple-2'].main}`
        : `1px solid ${theme.palette['grey-5'].main}`,
    },
    '&:hover:not(.Mui-disabled, .Mui-error)': {
      borderBottom: `1px solid ${theme.palette['purple-2'].main}`,
    },
    '& .MuiInputBase-input': {
      color: theme.palette['grey-8'].main,
      paddingBottom: '0',
    },
    '& .MuiFormLabel-root:not(.Mui-focused)': {
      color: theme.palette['grey-7'].main,
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
    },
  }),
};

export function renderTimeframe(
  start: string,
  end: string,
  dateFormat?: string
) {
  const startLabel = start ? renderTextDate(start, dateFormat) : '--';
  const endLabel = end ? renderTextDate(end, dateFormat) : '--';
  return `${startLabel} - ${endLabel}`;
}
