export const TODAY = 'TODAY';
export const YESTERDAY = 'YESTERDAY';
export const THIS_WEEK = 'THIS_WEEK';
export const PAST_7_DAYS = 'PAST_7_DAYS';
export const LAST_WEEK = 'LAST_WEEK';
export const MONTH_TO_DATE = 'MONTH_TO_DATE';
export const LAST_MONTH = 'LAST_MONTH';
export const PAST_30_DAYS = 'PAST_30_DAYS';
export const PAST_90_DAYS = 'PAST_90_DAYS';
export const YEAR_TO_DATE = 'YEAR_TO_DATE';
export const LAST_YEAR = 'LAST_YEAR';

export const TIMEFRAMES = {
  [TODAY]: { label: 'Today', value: TODAY },
  [YESTERDAY]: { label: 'Yesterday', value: YESTERDAY },
  [THIS_WEEK]: { label: 'This week', value: THIS_WEEK },
  [PAST_7_DAYS]: { label: 'Past 7 days', value: PAST_7_DAYS },
  [LAST_WEEK]: { label: 'Last week', value: LAST_WEEK },
  [MONTH_TO_DATE]: { label: 'Month to date', value: MONTH_TO_DATE },
  [LAST_MONTH]: { label: 'Last month', value: LAST_MONTH },
  [PAST_30_DAYS]: { label: 'Past 30 days', value: PAST_30_DAYS },
  [PAST_90_DAYS]: { label: 'Past 90 days', value: PAST_90_DAYS },
  [YEAR_TO_DATE]: { label: 'Year to date', value: YEAR_TO_DATE },
  [LAST_YEAR]: { label: 'Last year', value: LAST_YEAR },
} as const;

export type Timeframe = keyof typeof TIMEFRAMES;
