import { twMerge } from 'tailwind-merge';
import { type Timeframe, TIMEFRAMES } from '@/constants/timeframes';
import List from '@mui/material/List';
import ListItem from './ListItem';
import { DateRange } from '@/types/dates';
import { classes, getDefinedTimeframe } from './utils';

export type DefaultOptionsListProps = {
  extended?: boolean;
  weekStartsOn?: 0 | 1;
  onSelect: (timeframe: DateRange) => void;
};

export default function DefaultOptionsList({
  extended,
  weekStartsOn,
  onSelect,
}: DefaultOptionsListProps) {
  const handleSelectTimeframe = (timeframe: Timeframe) => {
    onSelect(getDefinedTimeframe(timeframe, weekStartsOn));
  };

  return (
    <List
      className={twMerge(
        classes.list,
        extended ? classes.extended : classes.unextended
      )}>
      {Object.values(TIMEFRAMES).map(({ label, value }) => (
        <ListItem
          key={value}
          label={label}
          onClick={() => handleSelectTimeframe(value)}
        />
      ))}
    </List>
  );
}
