import { twMerge } from 'tailwind-merge';
import { isSameDay, isWithinInterval, parseISO } from 'date-fns';
import { theme } from '@/utils/theme';

function checkDay(day: Date, startDate: string, endDate: string) {
  const start = parseISO(startDate);
  const end = parseISO(endDate);
  const isStartDay = isSameDay(day, start);
  const isEndDay = isSameDay(day, end);
  return {
    isStartDay,
    isEndDay,
    isSameDay: isStartDay && isEndDay,
    isInRange: isWithinInterval(day, { start, end }),
  };
}

export function getDayClassName(
  className: string | undefined,
  day: Date,
  startDate: string,
  endDate: string
) {
  const { isStartDay, isSameDay, isEndDay, isInRange } = checkDay(
    day,
    startDate,
    endDate
  );
  let dayClasses = '';
  if (isSameDay) dayClasses = 'range-day same-day';
  else if (isStartDay) dayClasses = 'range-day start-day';
  else if (isEndDay) dayClasses = 'range-day end-day';
  else if (isInRange) dayClasses = 'range-day';
  return twMerge(className, dayClasses);
}

export function getDayStyling(day: Date, startDate: string, endDate: string) {
  const { isStartDay, isSameDay, isEndDay, isInRange } = checkDay(
    day,
    startDate,
    endDate
  );
  if (isSameDay) return styles.sameDay;
  else if (isStartDay) return styles.startDay;
  else if (isEndDay) return styles.endDay;
  else if (isInRange) return styles.dayInRange;
  return {};
}

const edgeDayStyles = {
  backgroundColor: `${theme.palette['purple-2'].main} !important`,
  color: theme.palette['white'].main,
  // Default is 36 with a 2px horizontal margin, edge days lose one margin
  width: '38px',
};

export const styles = {
  startDay: {
    '&': {
      ...edgeDayStyles,
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      marginRight: '0px',
    },
  },
  endDay: {
    '&': {
      ...edgeDayStyles,
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
      marginLeft: '0px',
    },
  },
  sameDay: {
    '&': {
      ...edgeDayStyles,
      // Default is 36 with a 2px horizontal margin
      width: '36px',
      marginLeft: '2px',
      marginRight: '2px',
    },
  },
  dayInRange: {
    '&:not(.Mui-selected)': {
      backgroundColor: theme.palette['purple-2'].main,
      color: theme.palette['white'].main,
      borderRadius: '0',
      marginLeft: 0,
      marginRight: 0,
      width: '40px', // Default is 36 with a 2px horizontal margin
    },
  },
  calendar: {
    '& .MuiPickersDay-root.Mui-selected, .start-day, .end-day': {
      fontWeight: '700',
    },
    '& .MuiPickersYear-yearButton.Mui-selected': {
      backgroundColor: theme.palette['purple-2'].main,
    },
    '& .MuiPickersDay-today.range-day:not(.Mui-selected)': {
      border: `1px solid ${theme.palette['purple-2'].main}`,
    },
    '& .MuiPickersDay-today:not(.Mui-selected):not(.range-day)': {
      border: `1px solid ${theme.palette['grey-2'].main}`,
      backgroundColor: theme.palette['grey-2'].main,
    },
    '& .MuiPickersDay-root:not(.range-day):hover': {
      backgroundColor: theme.palette['grey-2'].main,
    },
    '& .MuiPickersDay-root:focus.Mui-selected, & .MuiPickersDay-root.Mui-selected, & .MuiPickersDay-root.Mui-selected:hover':
      {
        backgroundColor: theme.palette['purple-2'].main,
      },
    '& .MuiDayCalendar-weekContainer .MuiPickersDay-root.range-day:last-of-type:not(.end-day):not(.same-day)':
      {
        // @ts-ignore
        borderTopRightRadius: theme.shape.rounded.lg,
        // @ts-ignore
        borderBottomRightRadius: theme.shape.rounded.lg,
      },
    '& .MuiDayCalendar-weekContainer .MuiPickersDay-root.range-day:first-of-type:not(.start-day):not(.same-day)':
      {
        // @ts-ignore
        borderTopLeftRadius: theme.shape.rounded.lg,
        // @ts-ignore
        borderBottomLeftRadius: theme.shape.rounded.lg,
      },
  },
};
