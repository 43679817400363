'use client';
import { useEffect, useState } from 'react';
import { usePath } from '../usePath';
import { DateRange } from '@/types/dates';
import { getInitialValue } from './utils';

export function useTimeframe(defaultValue: DateRange) {
  const { searchParams, pushParams } = usePath();
  const [timeframe, setTimeframe] = useState(
    getInitialValue(searchParams, defaultValue)
  );

  useEffect(() => {
    pushParams({ startDate: timeframe[0], endDate: timeframe[1] });
  }, [timeframe]);

  return [timeframe, setTimeframe] as const;
}
