import { DateRange } from '@/types/dates';
import { theme } from '@/utils/theme';

export const ACTION = {
  PICK_START: 'PICK_START',
  PICK_END: 'PICK_END',
};

export const classes = {
  paper: 'flex',
  title:
    'pt-3 pb-2 mb-2 px-6 font-normal text-grey-8 text-base text-center bg-grey-2',
  buttonWrapper: 'flex pt-1 pb-3 px-6 flex items-center justify-end',
  button: 'py-1 px-4',
};

export const styles = {
  formControl: (isOpen: boolean) => ({
    '& .MuiFormLabel-root:not(.Mui-focused)': {
      color: theme.palette[isOpen ? 'purple-2' : 'grey-7'].main,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '6px',
      border: isOpen
        ? `2px solid ${theme.palette['purple-2'].main}`
        : // @ts-ignore
          `1px solid ${theme.palette.grey[400]}`, // Matches default Mui color
      transition: 'border-color 0.2s',
    },
    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette['purple-2'].main,
    },
  }),
  popover: {
    '& .MuiPaper-root': {
      // @ts-ignore
      borderRadius: theme.shape.rounded['2xl'],
    },
  },
};

export function getInitialStart(
  value?: DateRange | [string, null | undefined] | [null | undefined, string]
) {
  // If there's no start, default to today
  if (!value || !value[0]) {
    return new Date().toISOString();
  }
  return value[0];
}

export function getInitialEnd(
  value?: DateRange | [string, null | undefined] | [null | undefined, string]
): string {
  // If there's a start but no end, match the start time
  if (value && value[0] && !value[1]) {
    return value[0];
  } else if (value && !value[0] && value[1]) {
    // If there's an end but no start, match the end time
    return value[1];
  } else {
    // Otherwise default to today (as does start)
    return new Date().toISOString();
  }
}
